import category from './category';
import { commodity_full, commodity_short } from './commodity';
import messages from './messages';
import payout_provision_type from './payout_provision_type';
import product_type from './product_type';

export const enums = { commodity_short, commodity_full, category, messages, payout_provision_type, product_type };

export type EnumName = keyof typeof enums;

export default enums;
