import clsx from 'clsx';
import { ReactNode } from 'react';
import {
  LiaCheckCircle,
  LiaTimesCircle,
  LiaInfoCircleSolid,
  LiaExclamationCircleSolid,
  LiaTimesSolid,
  LiaClock,
} from 'react-icons/lia';
import 'react-toastify/dist/ReactToastify.css';

import './CustomToast.css';

const toastTypes = {
  success: {
    bg: 'bg-toast-green-100',
    border: 'border-toast-green-500',
    color: 'text-toast-green-500',
    icon: <LiaCheckCircle className="text-toast-green-500" size={28} />,
  },
  error: {
    bg: 'bg-toast-red-100',
    border: 'border-toast-red-500',
    color: 'text-toast-red-500',
    icon: <LiaTimesCircle className="text-toast-red-500" size={28} />,
  },
  info: {
    bg: 'bg-toast-blue-100',
    border: 'border-toast-blue-500',
    color: 'text-toast-blue-500',
    icon: <LiaInfoCircleSolid className="text-toast-blue-500" size={28} />,
  },
  warning: {
    bg: 'bg-toast-yellow-100',
    border: 'border-toast-yellow-500',
    color: 'text-toast-yellow-500',
    icon: <LiaExclamationCircleSolid className="text-toast-yellow-500" size={28} />,
  },
  pending: {
    bg: 'bg-toast-gray-100',
    border: 'border-toast-gray-500',
    color: 'text-toast-gray-500',
    icon: <LiaClock className="text-toast-gray-500" size={28} />,
  },
};

interface CustomToastProps {
  type: 'success' | 'error' | 'info' | 'warning' | 'pending';
  message: ReactNode;
  closeToast?: () => void;
  hasIcon?: boolean;
  hasCloseButton?: boolean;
  className?: string;
}

export default function CustomToast({
  type,
  message,
  closeToast,
  className,
  hasIcon = true,
  hasCloseButton = true,
}: CustomToastProps) {
  const toastType = toastTypes[type];

  return (
    <div
      className={clsx(
        'flex w-full items-center gap-4 rounded-md border p-4',
        toastType.bg,
        toastType.border,
        className
      )}
    >
      {hasIcon && <div>{toastType.icon}</div>}
      <div className={clsx('flex-1 text-sm', toastType.color)}>{message}</div>
      {hasCloseButton && (
        <button className={clsx(toastType.color, 'hover:text-gray-700')} onClick={closeToast}>
          <LiaTimesSolid size={16} />
        </button>
      )}
    </div>
  );
}
