import { FC } from 'react';
import { LiaIndustrySolid } from 'react-icons/lia';
import { Link } from 'react-router-dom';

import oceanLogo from 'app/assets/svg/ocean-logo.svg';

import styles from './Sidebar.module.scss';

const Sidebar: FC = () => {
  return (
    <div className={styles.sidebar}>
      <Link className={styles.logo} to="/">
        <img alt="Ocean Logo" src={oceanLogo} />
      </Link>
      <nav className={styles.nav}>
        <Link className={styles.link} to="/suppliers">
          <LiaIndustrySolid className={styles.icon} />
        </Link>
      </nav>
    </div>
  );
};

export default Sidebar;
