import { useContext } from 'react';

import { SetAuthContext } from '../context';

/**
 * Return setState action
 * - undefined = loading
 * - null = not logged in
 * - AuthContextState ({token, user}) = logged user
 */
export default function useSetAuthState() {
  return useContext(SetAuthContext);
}
