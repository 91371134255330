import t from 'core/helpers/t';

export const commodity_short = {
  gas: t('ZP'),
  electricity: t('EE'),
  electricity_gas: t('EE, ZP'),
};

export const commodity_full = {
  gas: t('Zemný plyn'),
  electricity: t('Elektrická energia'),
  electricity_gas: t('Elektrická energia, Zemný plyn'),
};
