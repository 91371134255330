import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomToast from 'core/components/CustomToast';
import translateEnum from 'core/helpers/translateEnum';

/**
 * Watch for router status errors and display toast
 */
export default function WatchRouteErrors() {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.errorCode) {
      toast(<CustomToast message={translateEnum('messages', location.state.errorCode)} type="error" />);
    }
  }, [location.state]);

  return null;
}
